<template>
    <div v-if="this.$atividades.permissoes.includes(19)">
        <vs-row
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
        >
            <vs-col vs-w="4" vs-justify="flex-start" vs-align="flex-start">
                <h4 style="color: #b4aa99">Gerenciamento de reportes.</h4>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="8">
                <v-col>
                    <vs-input 
                        type="date"
                        label="Criado de"
                        size="small"
                        v-model="filtro.criadoDe"
                    />
                </v-col>
                <v-col>
                    <vs-input 
                        type="date"
                        label="Criado até"
                        size="small"
                        v-model="filtro.criadoAte"
                    />
                </v-col>
                <v-col>
                    <vs-input 
                        type="date"
                        label="Concluído de"
                        size="small"
                        v-model="filtro.concluidoDe"
                    />
                </v-col>
                <v-col>
                    <vs-input 
                        type="date"
                        label="Concluído até"
                        size="small"
                        v-model="filtro.concluidoAte"
                    />
                </v-col>
                <v-col>
                    <vs-input 
                        type="date"
                        label="Prazo de"
                        size="small"
                        v-model="filtro.prazoDe"
                    />
                </v-col>
                <v-col>
                    <vs-input 
                        type="date"
                        label="Prazo até"
                        size="small"
                        v-model="filtro.prazoAte"
                    />
                </v-col>
                <el-button
                    size="small"
                    type="primary"
                    :disabled="blockButton"
                    class="mx-1 mb-4"
                    plain
                    @click="filtrar()"
                >FILTRAR</el-button
                >
            </vs-col>
        </vs-row>
        <vs-row>
            <v-col >
              <vs-input 
                type="text"
                label="Responsável"
                size="small"
                v-model="filtro.responsavel"
                class="ml-auto"
              />
            </v-col>
            <v-col cols="2" class="mr-5">
              <vs-input 
                type="text"
                label="Busca por título ou descrição"
                size="small"
                v-model="filtro.busca"
              />
            </v-col>
        </vs-row>       
        <vs-row vs-type="flex" vs-justify="flex-start" class="mt-8">
        <vs-col vs-w="2.4" class="px-2">
            <div>
            <vx-card style="background-color: #000a12">
                <vs-row vs-type="flex">
                <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                >
                    <div>
                        <h5 style="color: white">
                            <b>ABERTOS: {{ reportsAbertos.length }}</b>
                        </h5>
                    </div>
                </vs-col>
                </vs-row>
            </vx-card>
            <perfect-scrollbar
                class="scoll-os-confirmacao"
                
            >
                <div id="osLista" class="vs-con-loading__container">
                <div v-if="!reportsAbertos.length > 0">
                    <div class="con-colors">
                    <ul>
                        <span
                        class="px-3 pt-2"
                        style="color: grey !important;font-weight: 1000 !important;"
                        >
                        <vs-icon
                            size="large"
                            icon="sentiment_dissatisfied"
                        ></vs-icon>
                        </span>
                    </ul>
                    </div>
                </div>

                <div v-for="data in reportsAbertos" :key="data.id" class="mx-2">
                    <vx-card :class="cardClass" class="mr-10 my-3">
                        <template slot="no-body">
                            <div class="item-details px-2" @click="(iniciarModal = true), (selected = {...data}), (tituloModalEdit = {...data})">
                                <div class="my-1">
                                    <div>
                                    <vs-col vs-w="12">
                                        <vs-col
                                            class="mt-1"
                                            vs-w="12"
                                            vs-type="flex"
                                            vs-justify="flex-start"
                                            vs-align="flex-start"
                                        >
                                        <span >
                                            <b style="font-size:17px;">
                                                #{{ data.id }}: {{ data.nome }} 
                                            </b>
                                        </span>
                                        
                                        </vs-col>
                                        <div
                                            class="w-full"
                                            
                                        >
                                            <vs-col
                                                vs-w="12"
                                                class="mt-3"
                                                vs-type="flex"
                                                vs-justify="flex-start"
                                                vs-align="flex-start"
                                            >
                                                <span class="item-description text-md span-limitado">
                                                    Descrição: 
                                                    <b>{{ data.descricao }}</b>
                                                </span>
                                            </vs-col>
                                            <vs-col
                                                vs-w="12"
                                                vs-type="flex"
                                                vs-justify="flex-start"
                                                vs-align="flex-start"
                                            >
                                                <span class="item-description text-md">
                                                    Criador: 
                                                    <b>{{ data.nome_criador }}</b>
                                                </span>
                                            </vs-col>
                                            <vs-col
                                                vs-w="12"
                                                vs-type="flex"
                                                vs-justify="flex-start"
                                                vs-align="flex-start"
                                            >
                                                <span class="item-description text-md">
                                                    Criado em:
                                                    <b>{{ data.criado_em }}</b>
                                                </span>
                                            </vs-col>
                                        </div>
                                    </vs-col>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </vx-card>
                </div>
                </div>
            </perfect-scrollbar>
            </div>
        </vs-col>
        <vs-col vs-w="2.4" class="px-2">
            <div>
            <vx-card style="background-color: #e2ce12">
                <vs-row vs-type="flex">
                <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                >
                    <div>
                    <h5 style="color: white">
                        <b>APROVADOS: {{ reportsAnalise.length }}</b>
                    </h5>
                    </div>
                </vs-col>
                </vs-row>
            </vx-card>
            <perfect-scrollbar
                class="scoll-os-confirmacao"
            >
                <div id="osConfirmados" class="vs-con-loading__container">
                <div v-if="!reportsAnalise.length > 0">
                    <div class="con-colors">
                    <ul>
                        <span
                        class="px-3 pt-2"
                        style="color: grey !important;font-weight: 1000 !important;"
                        >
                        <vs-icon
                            size="large"
                            icon="sentiment_dissatisfied"
                        ></vs-icon>
                        </span>
                    </ul>
                    </div>
                </div>

                <div
                    v-for="data in reportsAnalise"
                    :key="data.id"
                    class="mx-2"
                >
                    <vx-card :class="cardClass" class="mr-10 my-3">
                    <template slot="no-body">
                        <div class="item-details pl-2 pr-1" @click="(iniciarModal = true), (selected = {...data}), (tituloModalEdit = {...data})">
                        <div class="my-1">
                            <div>
                                <vs-col vs-w="11">
                                    <vs-col
                                        vs-w="12"
                                        class="mt-1"
                                        vs-type="flex"
                                        vs-justify="flex-start"
                                        vs-align="flex-start"
                                    >
                                        <b style="font-size:17px;">
                                            #{{ data.id }}: {{ data.nome }}
                                        </b>
                                    </vs-col>
                                    <div
                                        class="w-full"
                                    >
                                        <vs-col
                                            vs-w="12"
                                            class="mt-3"
                                            vs-type="flex"
                                            vs-justify="flex-start"
                                            vs-align="flex-start"
                                        >
                                            <span class="item-description text-md span-limitado">
                                                Descrição: 
                                                <b>{{ data.descricao }}</b>
                                            </span>
                                        </vs-col>
                                        <vs-col
                                            vs-w="12"
                                            vs-type="flex"
                                            vs-justify="flex-start"
                                            vs-align="flex-start"
                                        >
                                            <span class="item-description text-md">
                                                Criador: 
                                                <b>{{ data.nome_criador }}</b>
                                            </span>
                                        </vs-col>
                                        <vs-col
                                            vs-w="12"
                                            vs-type="flex"
                                            vs-justify="flex-start"
                                            vs-align="flex-start"
                                        >
                                            <span class="item-description text-md">
                                                Responsável: 
                                                <b>
                                                    {{ data.nome_responsavel }}
                                                </b>
                                            </span>
                                        </vs-col>
                                        <vs-col
                                            vs-w="12"
                                            vs-type="flex"
                                            vs-justify="flex-start"
                                            vs-align="flex-start"
                                        >
                                            <span class="item-description text-md">
                                                Aprovador: 
                                                <b>
                                                    {{ data.nome_aprovador }}
                                                </b>
                                            </span>
                                        </vs-col>
                                        <vs-col
                                            vs-w="12"
                                            vs-type="flex"
                                            vs-justify="flex-start"
                                            vs-align="flex-start"
                                        >
                                            <span class="item-description text-md">
                                                Criado em:
                                                    <b>
                                                        {{ data.criado_em }}
                                                    </b>
                                            </span>
                                        </vs-col>
                                        <vs-col
                                            vs-w="12"
                                            vs-type="flex"
                                            vs-justify="flex-start"
                                            vs-align="flex-start"
                                        >
                                            <span v-if="new Date(data.prazo) < hoje" class="item-description text-md" style="color:red">
                                                Prazo:
                                                <b>{{ data.prazo_exibicao }}</b>
                                            </span>
                                            <span v-else class="item-description text-md">
                                                Prazo:
                                                <b>{{ data.prazo_exibicao }}</b>
                                            </span>
                                        </vs-col>
                                    </div>
                                </vs-col>
                                <vs-col
                                    vs-w="1"
                                    vs-type="flex"
                                    vs-justify="flex-end"
                                    vs-align="flex-end"
                                >
                                    <Badge
                                        v-if="data.pontos_funcao > 0"
                                        :text="data.pontos_funcao.toString()"
                                        color="warning"
                                    ></Badge>
                                </vs-col>
                            </div>
                        </div>
                        </div>
                    </template>
                    </vx-card>
                </div>
                </div>
            </perfect-scrollbar>
            </div>
        </vs-col>
        <vs-col vs-w="2.4" class="px-2">
            <div>
            <vx-card style="background-color: #3c12e2">
                <vs-row vs-type="flex">
                <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                >
                    <div>
                    <h5 style="color: white">
                        <b>EM DESENVOLVIMENTO: {{ reportsDesenvolvimento.length }}</b>
                    </h5>
                    </div>
                </vs-col>
                </vs-row>
            </vx-card>
            <perfect-scrollbar
                class="scoll-os-confirmacao"
            >
                <div id="osRemarcados" class="vs-con-loading__container">
                <div v-if="!reportsDesenvolvimento.length > 0">
                    <div class="con-colors">
                    <ul>
                        <span
                        class="px-3 pt-2"
                        style="color: grey !important; font-weight: 1000 !important;"
                        >
                        <vs-icon
                            size="large"
                            icon="sentiment_dissatisfied"
                        ></vs-icon>
                        </span>
                    </ul>
                    </div>
                </div>

                <div
                    v-for="data in reportsDesenvolvimento"
                    :key="data.id"
                    class="mx-2"
                >
                    <vx-card :class="cardClass" class="mr-10 my-3">
                        <template slot="no-body">
                            <div class="item-details pl-2 pr-1" @click="(iniciarModal = true), (selected = {...data}), (tituloModalEdit = {...data})">
                                <div class="my-1">
                                    <div>
                                        <vs-col vs-w="11">
                                            <vs-col
                                            vs-w="12"
                                            class="mt-1"
                                            vs-type="flex"
                                            vs-justify="flex-start"
                                            vs-align="flex-start"
                                            >
                                                <b style="font-size:17px;">
                                                    #{{ data.id }}: {{ data.nome }}
                                                </b>
                                            </vs-col>
                                            <div
                                                class="w-full"
                                            >
                                            <vs-col
                                                    vs-w="12"
                                                    class="mt-3"
                                                    vs-type="flex"
                                                    vs-justify="flex-start"
                                                    vs-align="flex-start"
                                                >
                                                    <span class="item-description text-md span-limitado">
                                                        Descrição: 
                                                        <b>{{ data.descricao }}</b>
                                                    </span>
                                                </vs-col>
                                            <vs-col
                                                vs-w="12"
                                                vs-type="flex"
                                                vs-justify="flex-start"
                                                vs-align="flex-start"
                                            >
                                                <span class="item-description text-md">
                                                Criador: 
                                                <b>
                                                    {{ data.nome_criador }}
                                                </b>
                                                </span>
                                            </vs-col>
                                            <vs-col
                                                vs-w="12"
                                                vs-type="flex"
                                                vs-justify="flex-start"
                                                vs-align="flex-start"
                                            >
                                                <span class="item-description text-md">
                                                    Responsável: 
                                                    <b>
                                                        {{ data.nome_responsavel }}
                                                    </b>
                                                </span>
                                            </vs-col>
                                            <vs-col
                                                vs-w="12"
                                                class="mt-1"
                                                vs-type="flex"
                                                vs-justify="flex-start"
                                                vs-align="flex-start"
                                            >
                                                <span>
                                                    Aprovador:
                                                    <b>
                                                        {{ data.nome_aprovador }}
                                                    </b>
                                                </span>
                                            </vs-col>
                                            <vs-col
                                                vs-w="12"
                                                class="mt-1"
                                                vs-type="flex"
                                                vs-justify="flex-start"
                                                vs-align="flex-start"
                                            >
                                                <span>
                                                    Criado em:
                                                    <b>
                                                        {{ data.criado_em }}
                                                    </b>
                                                </span>
                                            </vs-col>
                                            <vs-col
                                                vs-w="12"
                                                vs-type="flex"
                                                vs-justify="flex-start"
                                                vs-align="flex-start"
                                            >
                                                <span v-if="new Date(data.prazo) < hoje" class="item-description text-md" style="color:red">
                                                    Prazo:
                                                    <b>{{ data.prazo_exibicao }}</b>
                                                </span>
                                                <span v-else class="item-description text-md">
                                                    Prazo:
                                                    <b>{{ data.prazo_exibicao }}</b>
                                                </span>
                                            </vs-col>
                                        </div>
                                    </vs-col>
                                    <vs-col
                                        vs-w="1"
                                        vs-type="flex"
                                        vs-justify="flex-end"
                                        vs-align="flex-end"
                                    >
                                        <Badge
                                            v-if="data.pontos_funcao > 0"
                                            :text="data.pontos_funcao.toString()"
                                            color="warning"
                                        ></Badge>
                                    </vs-col>
                                </div>
                                </div>
                            </div>
                        </template>
                    </vx-card>
                </div>
                </div>
            </perfect-scrollbar>
            </div>
        </vs-col>
        <vs-col vs-w="2.4" class="px-2">
            <div>
            <vx-card style="background-color: #10cb8d">
                <vs-row vs-type="flex">
                <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                >
                    <div>
                    <h5 style="color: white">
                        <b>EM HOMOLOGAÇÂO: {{ reportsHomologacao.length }}</b>
                    </h5>
                    </div>
                </vs-col>
                </vs-row>
            </vx-card>
            <perfect-scrollbar
                class="scoll-os-confirmacao"
            >
                <div id="osRemarcados" class="vs-con-loading__container">
                <div v-if="!reportsHomologacao.length > 0">
                    <div class="con-colors">
                    <ul>
                        <span
                            class="px-3 pt-2"
                            style="color: grey !important;font-weight: 1000 !important;"
                        >
                        <vs-icon
                            size="large"
                            icon="sentiment_dissatisfied"
                        ></vs-icon>
                        </span>
                    </ul>
                    </div>
                </div>

                <div
                    v-for="data in reportsHomologacao"
                    :key="data.id"
                    class="mx-2"
                >
                    <vx-card :class="cardClass" class="mr-10 my-3">
                    <template slot="no-body">
                        <div class="item-details pl-2 pr-1" @click="(iniciarModal = true), (selected = {...data}), (tituloModalEdit = {...data})">
                            <div class="my-1">
                                <div>
                                    <vs-col vs-w="11">
                                        <vs-col
                                            vs-w="12"
                                            class="mt-1"
                                            vs-type="flex"
                                            vs-justify="flex-start"
                                            vs-align="flex-start"
                                        >
                                            <b style="font-size:17px;">
                                                #{{ data.id }}: {{ data.nome }}
                                            </b>
                                        </vs-col>
                                        <div
                                            class="w-full"
                                        >
                                        <vs-col
                                                vs-w="12"
                                                class="mt-3"
                                                vs-type="flex"
                                                vs-justify="flex-start"
                                                vs-align="flex-start"
                                            >
                                                <span class="item-description text-md span-limitado">
                                                    Descrição: 
                                                    <b>{{ data.descricao }}</b>
                                                </span>
                                            </vs-col>
                                        <vs-col
                                            vs-w="12"
                                            vs-type="flex"
                                            vs-justify="flex-start"
                                            vs-align="flex-start"
                                        >
                                            <span class="item-description text-md">
                                                Criador:
                                                <b>
                                                    {{ data.nome_criador }}
                                                </b>
                                            </span>
                                        </vs-col>
                                        <vs-col
                                            vs-w="12"
                                            vs-type="flex"
                                            vs-justify="flex-start"
                                            vs-align="flex-start"
                                        >
                                            <span class="item-description text-md">
                                                Responsável:
                                                <b>
                                                    {{ data.nome_responsavel }}
                                                </b>
                                            </span>
                                        </vs-col>
                                        <vs-col
                                            vs-w="12"
                                            class="mt-1"
                                            vs-type="flex"
                                            vs-justify="flex-start"
                                            vs-align="flex-start"
                                        >
                                            <span>
                                                Aprovador:
                                                <b>
                                                    {{ data.nome_aprovador }}
                                                </b>
                                            </span>
                                        </vs-col>
                                        <vs-col
                                            vs-w="12"
                                            class="mt-1"
                                            vs-type="flex"
                                            vs-justify="flex-start"
                                            vs-align="flex-start"
                                        >
                                            <span>
                                                Criado em:
                                                <b>
                                                    {{ data.criado_em }}
                                                </b>
                                            </span>
                                        </vs-col>
                                        <vs-col
                                            vs-w="12"
                                            vs-type="flex"
                                            vs-justify="flex-start"
                                            vs-align="flex-start"
                                        >
                                            <span v-if="new Date(data.prazo) < hoje" class="item-description text-md" style="color:red">
                                                Prazo:
                                                <b>{{ data.prazo_exibicao }}</b>
                                            </span>
                                            <span v-else class="item-description text-md">
                                                Prazo:
                                                <b>{{ data.prazo_exibicao }}</b>
                                            </span>
                                        </vs-col>
                                    </div>
                                    </vs-col>
                                    <vs-col
                                        vs-w="1"
                                        vs-type="flex"
                                        vs-justify="flex-end"
                                        vs-align="flex-end"
                                    >
                                        <Badge
                                            v-if="data.pontos_funcao > 0"
                                            :text="data.pontos_funcao.toString()"
                                            color="warning"
                                        ></Badge>
                                    </vs-col>
                                </div>
                            </div>
                        </div>
                    </template>
                    </vx-card>
                </div>
                </div>
            </perfect-scrollbar>
            </div>
        </vs-col>
        <vs-col vs-w="2.4" class="px-2">
            <div>
            <vx-card style="background-color: #12e224">
                <vs-row vs-type="flex">
                <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                >
                    <div>
                    <h5 style="color: white">
                        <b>CONCLUÍDOS: {{ reportsConcluidos.length }}</b>
                    </h5>
                    </div>
                </vs-col>
                </vs-row>
            </vx-card>
            <perfect-scrollbar
                class="scoll-os-confirmacao"
            >
                <div id="osRemarcados" class="vs-con-loading__container">
                <div v-if="!reportsConcluidos.length > 0">
                    <div class="con-colors">
                    <ul>
                        <span
                        class="px-3 pt-2"
                        style="color: grey !important;font-weight: 1000 !important;"
                        >
                        <vs-icon
                            size="large"
                            icon="sentiment_dissatisfied"
                        ></vs-icon>
                        </span>
                    </ul>
                    </div>
                </div>

                <div
                    v-for="data in reportsConcluidos"
                    :key="data.id"
                    class="mx-2"
                >
                    <vx-card :class="cardClass" class="mr-10 my-3">
                    <template slot="no-body">
                        <div class="item-details pl-2 pr-1" @click="(iniciarModal = true), (selected = {...data}), (tituloModalEdit = {...data})">
                            <div class="my-1">
                                <div>
                                    <vs-col vs-w="11">
                                        <vs-col
                                            vs-w="12"
                                            class="mt-1"
                                            vs-type="flex"
                                            vs-justify="flex-start"
                                            vs-align="flex-start"
                                        >
                                            <b style="font-size:17px;">
                                                #{{ data.id }}: {{ data.nome }}
                                            </b>
                                        </vs-col>
                                        <div
                                            class="w-full"
                                            
                                        >
                                            <vs-col
                                                vs-w="12"
                                                class="mt-3"
                                                vs-type="flex"
                                                vs-justify="flex-start"
                                                vs-align="flex-start"
                                            >
                                                <span class="item-description text-md span-limitado">
                                                    Descrição: 
                                                    <b>{{ data.descricao }}</b>
                                                </span>
                                            </vs-col>
                                            <vs-col
                                                vs-w="12"
                                                vs-type="flex"
                                                vs-justify="flex-start"
                                                vs-align="flex-start"
                                            >
                                                <span class="item-description text-md">
                                                    Criador:
                                                    <b>
                                                        {{ data.nome_criador }}
                                                    </b>
                                                </span>
                                            </vs-col>
                                            <vs-col
                                                vs-w="12"
                                                vs-type="flex"
                                                vs-justify="flex-start"
                                                vs-align="flex-start"
                                            >
                                                <span class="item-description text-md">
                                                    Responsável:
                                                    <b>
                                                        {{ data.nome_responsavel }}
                                                    </b>
                                                </span>
                                            </vs-col>
                                            <vs-col
                                                vs-w="12"
                                                class="mt-1"
                                                vs-type="flex"
                                                vs-justify="flex-start"
                                                vs-align="flex-start"
                                            >
                                                <span>
                                                    Aprovador:
                                                    <b>
                                                        {{ data.nome_aprovador }}
                                                    </b>
                                                </span>
                                            </vs-col>
                                            <vs-col
                                                vs-w="12"
                                                class="mt-1"
                                                vs-type="flex"
                                                vs-justify="flex-start"
                                                vs-align="flex-start"
                                            >
                                                <span>
                                                    Criado em:
                                                    <b>
                                                        {{ data.criado_em }}
                                                    </b>
                                                </span>
                                            </vs-col>
                                            <vs-col
                                                vs-w="12"
                                                class="mt-1"
                                                vs-type="flex"
                                                vs-justify="flex-start"
                                                vs-align="flex-start"
                                            >
                                                <span>
                                                    Concluído em:
                                                    <b>
                                                        {{ data.concluido_em }}
                                                    </b>
                                                </span>
                                            </vs-col>
                                            <vs-col
                                                vs-w="12"
                                                vs-type="flex"
                                                vs-justify="flex-start"
                                                vs-align="flex-start"
                                            >
                                                <span class="item-description text-md">
                                                    Prazo:
                                                    <b>{{ data.prazo_exibicao }}</b>
                                                </span>
                                            </vs-col>
                                        </div>
                                    </vs-col>
                                    <vs-col
                                        vs-w="1"
                                        vs-type="flex"
                                        vs-justify="flex-end"
                                        vs-align="flex-end"
                                    >
                                        <Badge
                                            v-if="data.pontos_funcao > 0"
                                            :text="data.pontos_funcao.toString()"
                                            color="warning"
                                        ></Badge>
                                    </vs-col>
                                </div>
                            </div>
                        </div>
                    </template>
                    </vx-card>
                </div>
                </div>
            </perfect-scrollbar>
            </div>
        </vs-col>
        </vs-row>
        <vs-popup
            class="pop-up"
            :title="'' + tituloModalEdit.nome"
            :active.sync="iniciarModal"
        >
            <vs-row vs-type="flex" vs-align="center">
                <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                >
                    <label for="txtTitulo" style="margin-left:40px;">Título</label>
                </vs-col>
                <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class="px-10"
                >
                    <vs-input
                        id="txtTitulo"
                        type="text"
                        v-model="selected.nome"
                        style="width: 530px;"
                    />
                </vs-col>
                <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="flex-start"
                    class="mt-5"
                >
                    <label for="textareaDescricao" style="margin-left:40px;">Descrição</label>
                </vs-col>
                <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class="px-10"
                >
                    <vs-textarea
                        id="textareaDescricao"
                        :rows="3"
                        v-model="selected.descricao"
                    />
                </vs-col>
                <vs-col
                    vs-w="6"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                >
                    <label for="selectStatus" style="margin-right:70px;">Selecionar status</label>
                    
                </vs-col>
                <vs-col
                    vs-w="6"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                >
                    <label for="selectResponsavel" style="margin-right:70px;">Definir responsável</label>
                </vs-col>
                <vs-col
                    vs-w="6"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                >
                    <el-select id="selectStatus" v-model="selected.id_status">
                        <el-option
                            v-for="status in ticketStatus"
                            :key="status.id"
                            :label="status.nome"
                            :value="status.id"
                        />
                    </el-select>
                </vs-col>
                <vs-col
                    vs-w="6"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                >
                    <el-select id="selectResponsavel" 
                        v-model="selected.id_responsavel"
                        filterable 
                        remote 
                        :remote-method="getColaboradorByBusca">

                        <el-option
                            v-for="responsavel in responsaveis"
                            :key="responsavel.id"
                            :label="responsavel.nome"
                            :value="responsavel.id"
                        />

                    </el-select>
                </vs-col>
                <vs-col
                    vs-w="6"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class="mt-8"
                >
                    <label for="selectStatus" style="margin-right:70px;">Definir prioridade</label>
                </vs-col>
                <vs-col
                    vs-w="6"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class="mt-8"
                >
                    <label for="definirPrazo" style="margin-right:110px;">Definir prazo</label>
                </vs-col>
                <vs-col
                    vs-w="6"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                >
                    <el-select id="selectStatus" v-model="selected.id_prioridade">
                        <el-option
                            v-for="prioridade in ticketPrioridades"
                            :key="prioridade.id"
                            :label="prioridade.nome"
                            :value="prioridade.id"
                        />
                    </el-select>
                </vs-col>
                <vs-col
                    vs-w="6"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                >
                    <vs-input
                        id="definirPrazo"
                        type="date"
                        v-model="selected.prazo"
                        style="width:215px;"
                    />
                </vs-col>
                <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="start"
                    vs-align="center"
                    class="mt-8"
                    style="margin-left:45px;"
                >
                    <label for="pontosFuncao">Pontos de função</label>
                </vs-col>
                <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="start"
                    vs-align="center"
                    style="margin-left:35px;"
                >
                    <vs-input
                        id="pontosFuncao"
                        type="number"
                        min="0"
                        v-model="selected.pontos_funcao"
                        style="width:215px;"
                    />
                </vs-col>
                <vs-col
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    class="mt-8"
                >
                    <vs-button @click="editarTicket">SALVAR</vs-button>
                </vs-col>
            </vs-row>
        </vs-popup>
    </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  data() {
    return {
        filtro: {
            criadoDe: null,
            criadoAte: null,
            concluidoDe: null,
            concluidoAte: null,
            prazoDe: null,
            prazoAte: null,
            repsonsavel: null,
            busca: null,
        },
        hoje: new Date(),
        settings: {
            maxScrollbarLength: 60,
            wheelSpeed: 0.8,
        },
        cardClass: "os-card overflow-hidden",
        selected: {},
        iniciarModal: false,
        blockButton: false,
        reportsAbertos: [],
        reportsAnalise: [],
        reportsDesenvolvimento: [],
        reportsHomologacao: [],
        reportsConcluidos: [],
        ticketStatus: [],
        ticketPrioridades: [],
        ticketEditado: {},
        responsaveis: [],
        tituloModalEdit: [],
    };
  },
  async mounted() {
    if(!this.$atividades.permissoes.includes(13)){
        this.$vs.notify({
            title: "Erro",
            text: "Você não tem permissão para acessar esta pagina",
            color: "danger"
        });
    }else{
        await this.reload()
    }
  },
  methods: {
    async filtrar() {
        this.blockButton = true;
        this.reload()
        this.blockButton = false;
    },

    async closeModalReagendar() {
        this.iniciarModal = await false;
    },

    async close() {
        this.iniciarModal = await false;
    },

    async loadingModal() {
        await this.$vs.loading({ container: "#loading", scale: 0.6 });
    },

    async getReportsAbertos(){
        try{
            this.reportsAbertos = await this.$http.post(`ticket-filtro/1`, this.filtro)
        }catch(error){
            const erro = this.$httpErrors(error);
            this.$vs.notify(erro);
        }
    },

    async getReportsAnalise(){
        try{
            this.reportsAnalise = await this.$http.post(`ticket-filtro/2`, this.filtro)
        }catch(error){
            const erro = this.$httpErrors(error);
            this.$vs.notify(erro);
        }
    },

    async getReportsDesenvolvimento(){
        try{
            this.reportsDesenvolvimento = await this.$http.post(`ticket-filtro/3`, this.filtro)
        }catch(error){
            const erro = this.$httpErrors(error);
            this.$vs.notify(erro);
        }
    },

    async getReportsHomologacao(){
        try{
            this.reportsHomologacao = await this.$http.post(`ticket-filtro/4`, this.filtro)
        }catch(error){
            const erro = this.$httpErrors(error);
            this.$vs.notify(erro);
        }
    },

    async getReportsConcluidos(){
        try{
            this.reportsConcluidos = await this.$http.post(`ticket-filtro/5`, this.filtro)
        }catch(error){
            const erro = this.$httpErrors(error);
            this.$vs.notify(erro);
        }
    },

    async getTicketStatus(){
        try{
            this.ticketStatus = await this.$http.get(`ticket-status`)
        }catch(error){
            const erro = this.$httpErrors(error);
            this.$vs.notify(erro);
        }
    },

    async getTicketPrioridades(){
        try{
            this.ticketPrioridades = await this.$http.get(`ticket-prioridades`)
        }catch(error){
            const erro = this.$httpErrors(error);
            this.$vs.notify(erro);
        }
    },

    async getColaboradorByBusca(textoDigitado){
        try{
            this.responsaveis = await this.$http.post(`usuario-by-texto`, {texto: textoDigitado})
        }catch(error){
            const erro = this.$httpErrors(error);
            this.$vs.notify(erro);
        }
    },

    async getResponsaveis(){
        try{
            this.responsaveis = await this.$http.get(`tickets-responsaveis`)
        }catch(error){
            const erro = this.$httpErrors(error);
            this.$vs.notify(erro);
        }
    },

    async editarTicket(){
        this.$vs.loading();
        try{
            await this.$http.put(`ticket/${this.selected.id}`, this.selected)
            this.$vs.notify({
                title: "Sucesso",
                text: "Ticket alterado com sucesso",
                color: "success"
            });
        }catch(error){
            const erro = this.$httpErrors(error);
            this.$vs.notify(erro);
        }finally{
            this.reload()
            this.$vs.loading.close();
        }
    },

    async reload(){
        Promise.all([
            // this.getOsLista(),
            // this.getosConfirmados(),
            // this.getOsRemarcados(),
            this.closeModalReagendar(),
            this.getReportsAbertos(),
            this.getReportsAnalise(),
            this.getReportsDesenvolvimento(),
            this.getReportsHomologacao(),
            this.getReportsConcluidos(),
            this.getTicketStatus(),
            this.getTicketPrioridades(),
            this.getResponsaveis()
        ])
    }
  },
  components: {
    ...components,
  },
};
</script>
<style scoped lang="scss">
.list-item {
  background-color: #fff;
  transition: background 0.1s ease-in-out;
  border-radius: 3px;
  font-size: 16px;
}
.list-item:hover {
  background: #dcdcdc;
}
.active-list-item {
  background-color: #000a12;
  transition: background 0.1s ease-in-out;
  border-radius: 3px;
  font-size: 16px;
  color: #ffca00;
}
.os-card {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.scoll-os-confirmacao {
  height: 70vh !important;
}

.pop-up{
    z-index: 1;
}

.span-limitado{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
    max-height: 6em;
}
</style>
